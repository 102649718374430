import { createRouter, createWebHistory } from 'vue-router'

import store from './store'
const scrollBehavior = (to, from, savedPosition) => {
    if (to.hash) {
        const element = document.querySelector(to.hash);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    } else if (savedPosition) {
        window.scrollTo({ top: savedPosition.y, behavior: 'smooth' });
    }
    return new Promise(resolve => {
        setTimeout(() => {
            if (to.hash) {
                resolve({ el: to.hash, behavior: 'smooth' });
            } else {
                resolve(savedPosition ? { x: 0, y: savedPosition.y } : { x: 0, y: 0 });
            }
        }, 500); // Adjust timing as necessary for page load or transitions
    });
}
const router = createRouter({
    "history": createWebHistory(),
    base: process.env.BASE_URL,
    scrollBehavior,
    // This is for the scroll top when click on any router link
    routes: [
        {
            publicPath: '',
            historyApiFallback: true,
            path: '',
            component: () => import('./layouts/full-layout/Layout'),
            meta: {
                requiresAuth: true
            },
            children: [
                {
                    name: '403',
                    path: '/403',
                    component: () => import('./views/errors/403'),
                },
                {
                    name: 'Dashboard',
                    path: '',
                    component: () => import('./views/Dashboard'),
                },
                {
                    name: 'Ambient temperatures',
                    path: 'ambienttemperatures',
                    meta: { model: 'ambienttemperature', action: 'read' },
                    component: () => import('./views/ambienttemperatures/List'),
                },
                {
                    name: 'Brands',
                    path: 'brands',
                    meta: { model: 'brand', action: 'read' },
                    component: () => import('./views/brands/List'),
                },
                {
                    name: 'Companies',
                    path: 'companies',
                    meta: { model: 'company', action: 'read' },
                    component: () => import('./views/companies/List'),
                },
                {
                    name: 'Companies edit',
                    path: 'companies/:id',
                    meta: { model: 'company' },
                    component: () => import('./views/companies/Edit'),
                },
                {
                    name: 'Contact',
                    path: 'contacts',
                    meta: { model: 'contact', action: 'read' },
                    component: () => import('./views/contacts/List'),
                },
                {
                    name: 'Contact edit',
                    path: 'contacts/:id',
                    meta: { model: 'contact' },
                    component: () => import('./views/contacts/Edit'),
                },
                {
                    name: 'Distribution device functions',
                    path: 'distributiondevicefunctions',
                    meta: { model: 'distributiondevicefunction', action: 'read' },
                    component: () => import('./views/distributiondevicefunctions/List'),
                },
                {
                    name: 'Distribution panels',
                    path: 'distributionpanels',
                    meta: { model: 'distributionpanel', action: 'read' },
                    component: () => import('./views/distributionpanels/List'),
                },
                {
                    name: 'Employees',
                    path: 'employees',
                    meta: { model: 'employee', action: 'read' },
                    component: () => import('./views/employees/List'),
                },
                {
                    name: 'Employees edit',
                    path: 'employees/:id',
                    meta: { model: 'employee' },
                    component: () => import('./views/employees/Edit'),
                },
                {
                    name: 'Equipment protection levels',
                    path: 'equipmentprotectionlevels',
                    meta: { model: 'equipmentprotectionlevel', action: 'read' },
                    component: () => import('./views/equipmentprotectionlevels/List'),
                },
                {
                    name: 'Fields',
                    path: 'fields',
                    meta: { model: 'field', action: 'read' },
                    component: () => import('./views/fields/List'),
                },
                {
                    name: 'Gas groups',
                    path: 'gasgroups',
                    meta: { model: 'gasgroup', action: 'read' },
                    component: () => import('./views/gasgroups/List'),
                },
                {
                    name: 'Inspection institute certifications',
                    path: 'inspectioninstitutecertifications',
                    meta: { model: 'inspectioninstitutecertification', action: 'read' },
                    component: () => import('./views/inspectioninstitutecertifications/List'),
                },
                {
                    name: 'Inspection codes',
                    path: 'inspectioncodes',
                    meta: { model: 'inspectioncode', action: 'read' },
                    component: () => import('./views/inspectioncodes/List'),
                },
                {
                    name: 'Inspection certificates',
                    path: 'inspectioncertificates',
                    meta: { model: 'inspectioncertificate', action: 'read' },
                    component: () => import('./views/inspectioncertificates/List'),
                },
                {
                    name: 'Ip indications',
                    path: 'ipindications',
                    meta: { model: 'ipindication', action: 'read' },
                    component: () => import('./views/ipindications/List'),
                },
                {
                    name: 'Ip types',
                    path: 'iptypes',
                    meta: { model: 'iptype', action: 'read' },
                    component: () => import('./views/iptypes/List'),
                },
                {
                    name: 'Line voltage',
                    path: 'linevoltage',
                    meta: { model: 'linevoltage', action: 'read' },
                    component: () => import('./views/linevoltage/List'),
                },
                {
                    name: 'Markering',
                    path: 'markering',
                    meta: { model: 'markering', action: 'read' },
                    component: () => import('./views/markering/List'),
                },
                {
                    name: 'Notes',
                    path: 'notes',
                    meta: { model: 'note', action: 'read' },
                    component: () => import('./views/notes/List'),
                },
                {
                    name: 'Profile',
                    path: 'profile',
                    component: () => import('./views/Profile'),
                },
                {
                    name: 'Regulations',
                    path: 'regulations',
                    meta: { model: 'regulation', action: 'read' },
                    component: () => import('./views/regulations/List'),
                },
                {
                    name: 'Role',
                    path: 'roles',
                    meta: { model: 'role', action: 'read' },
                    component: () => import('./views/roles/List'),
                },
                {
                    name: 'Role Edit',
                    path: 'roles/:id',
                    meta: { model: 'role' },
                    component: () => import('./views/roles/Edit'),
                },
                {
                    name: 'Search',
                    path: 'search',
                    meta: { model: 'search' },
                    component: () => import('./views/search/List'),
                },
                {
                    name: 'Settings Edit',
                    path: 'settings',
                    meta: { model: 'setting' },
                    component: () => import('./views/settings/Edit'),
                },
                {
                    name: 'Simple inspections',
                    path: 'simpleinspections',
                    meta: { name: 'simpleinspection', action: 'read' },
                    component: () => import('./views/simpleinspections/List'),
                },
                {
                    name: 'Simple inspections edit',
                    path: 'simpleinspections/:id',
                    meta: { model: 'simpleinspection' },
                    component: () => import('./views/simpleinspections/Edit'),
                },
                {
                    name: 'Simple equipment types',
                    path: 'simpleequipmenttypes',
                    meta: { model: 'simpleequipmenttype', action: 'read' },
                    component: () => import('./views/simpleequipmenttypes/List'),
                },
                {
                    name: 'Simple protection methods',
                    path: 'simpleprotectionmethods',
                    meta: { model: 'simpleprotectionmethod', action: 'read' },
                    component: () => import('./views/simpleprotectionmethods/List'),
                },
                {
                    name: 'Tags',
                    path: 'tags',
                    meta: { model: 'tag', action: 'read' },
                    component: () => import('./views/tags/List'),
                },
                {
                    name: 'Temperature classes',
                    path: 'temperatureclasses',
                    meta: { model: 'temperatureclass', action: 'read' },
                    component: () => import('./views/temperatureclasses/List'),
                },
                {
                    name: 'Templates',
                    path: 'templates',
                    meta: { model: 'template', action: 'read' },
                    component: () => import('./views/templates/List'),
                },
                {
                    name: 'Templates edit',
                    path: 'templates/:id',
                    meta: { model: 'template' },
                    component: () => import('./views/templates/Edit'),
                },
                {
                    name: 'Translations',
                    path: 'translations',
                    meta: { model: 'translation', action: 'read' },
                    component: () => import('./views/translations/List'),
                },
                {
                    name: 'Workflow',
                    path: 'workflows',
                    meta: { model: 'workflow', action: 'read' },
                    component: () => import('./views/workflows/List'),
                },
                {
                    name: 'Workflow Edit',
                    path: 'workflows/:id',
                    meta: { model: 'workflow' },
                    component: () => import('./views/workflows/Edit'),
                },
                {
                    name: 'Zone types',
                    path: 'zonetypes',
                    meta: { model: 'zonetype', action: 'read' },
                    component: () => import('./views/zonetypes/List'),
                },
            ]
        },
        {
            name: 'Login',
            path: '/login',
            component: () => import('./views/authentication/Login'),
        },
        {
            name: 'Error',
            path: '/error404',
            component: () => import('./views/Error'),
        },

        {
            path: '/authentication',
            component: () => import('./layouts/blank-layout/Blanklayout'),
        },
        {
            name: 'Complete registration',
            path: '/completeregistration',
            publicPath: '/completeregistration',
            component: () => import('./views/authentication/CompleteRegistration'),
            meta: {
                requiresAuth: false
            },
        },
        {
            name: 'Password reset',
            path: '/resetpassword',
            publicPath: '/resetpassword',
            component: () => import('./views/authentication/PasswordReset'),
            meta: {
                requiresAuth: false
            },
        },
        {
            publicPath: '',
            historyApiFallback: true,
            path: '',
            component: () => import('./layouts/full-layout/Layout'),
            children: [
                {
                    name: '403',
                    path: '/403',
                    component: () => import('./views/errors/403'),
                },
            ]
        },
    ],
})
router.beforeEach((to, from, next) => {
    if(to.path == '/completeregistration' || to.path == '/resetpassword') {
        localStorage.removeItem("path");
    }
    else if(to.path !== '/login') {
        var path = to.path;
        if (to.query.id) {
            path += "?id=" + to.query.id;
        }
        localStorage.setItem("path", path);
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {
            next();
            return;
        } else {
            next('/login');
        }

    } else {
        next();
    }
})

router.beforeEach((to, from, next) => {
    next();
})

export default router
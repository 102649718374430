<template>
    <v-textarea
      class="mt-5"
      color="primary"
      variant="outlined"
      density="compact"
      rows="10"
      :label="$store.getters.translate('send_notification')"
      v-model="new_value"
      :items="messagetemplates"
      single-line/>
    <v-btn color="primary" size="large" block @click="load">
      {{ $store.getters.translate("choose_from_message_templates") }}
    </v-btn>
    <BaseDraggableModal
        :name="modal_name"
        max-height="80vh"
        width="70vw"
        :fullscreen="$vuetify.display.xsOnly"
        :ref="modal_name">
      <v-card :disabled="loading" :loading="loading">
        <BaseModal>
          <template #header>
            <v-toolbar class="draggable_selector" color="primary" dense flat>
              <v-toolbar-title>{{ $store.getters.translate("messagetemplates") }}</v-toolbar-title>
              <v-spacer/>
              <v-btn size="small" @click="show_create = false; closeModal(modal_name)">
                <v-icon icon="mdi-close" size="x-large"/>
              </v-btn>
            </v-toolbar>
          </template>
          <template #content>
            <v-card-text class="ml-5 mr-5" v-if="!show_create">
              <v-data-table
                  :headers="headers"
                  :items="messagetemplates"
                  :loading="loading"
                  :show-select="false"
                  v-bind="footer_props">
                <template v-slot:item.name="{ item }">
                  <a @click="setMessage(item.message)">{{ item.name }}</a>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon @click="deleteRecord(item.id)" color="red" icon="mdi-trash-can"/>
                </template>
              </v-data-table>
            </v-card-text>
          </template>
          <template #footer>
            <template v-if="show_create">
              <v-textarea
                  class="ml-5 mr-5 mt-5"
                  color="primary"
                  variant="outlined"
                  density="compact"
                  rows="10"
                  :label="$store.getters.translate('notification')"
                  v-model="record.message"/>
              <v-footer>
                <v-btn class="mb-5" color="primary" size="large" block @click="save()">
                  {{ $store.getters.translate("save") }}
                </v-btn>
              </v-footer>
            </template>
            <template v-else>
              <v-footer>
                <v-btn class="mb-5" color="primary" size="large" block @click="show_create = true">
                  {{ $store.getters.translate("create") }}
                </v-btn>
              </v-footer>
            </template>
          </template>
        </BaseModal>
      </v-card>
    </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "./BaseModal";
import BaseDraggableModal from "./BaseDraggableModal";

export default {
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  props: ["modelValue", "modal_name"],
  data() {
    return {
      new_value: null,
      messagetemplates: [],
      loading: false,
      show_create: false,
      record: {},
      headers: [
        {
          title: this.$store.getters.translate("name"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          title: this.$store.getters.translate("actions"),
          sortable: false,
          align: "end",
          value: "actions",
        },
      ],
      footer_props: helpFunctions.footer_props,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      this.openModal(this.modal_name);
      this.getMessageTemplates();
    },
    save() {
      if(!this.record.message) {
        this.$toast.error(this.$store.getters.translate("please_enter_message"));
      }
      else {
        this.loading = true;
        this.$http
            .post(this.$store.getters.appUrl + "v2/messagetemplates", this.record)
            .then(() => {
              this.loading = false;
              this.show_create = false;
              this.$toast.success(this.$store.getters.translate("successfully_saved"));
              this.setMessage(this.record.message);
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toast.error(error.message);
              }
              this.loading = false;
            });
      }
    },
    async getMessageTemplates() {
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/messagetemplates")
        .then((response) => {
          this.loading = false;
          this.messagetemplates = response.data;
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toast.error(error.message);
          }
          this.loading = false;
        });
    },
    deleteRecord(id) {
      this.loading = true;
      this.$http
        .delete(this.$store.getters.appUrl + "v2/messagetemplates/" + id)
        .then(() => {
          this.loading = false;
          this.getMessageTemplates();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toast.error(error.message);
          }
          this.loading = false;
        });
    },
    setMessage(message) {
      this.new_value = message;
      this.closeModal(this.modal_name);
    },
  },
  watch: {
    new_value: {
      handler() {
        this.$emit("change", this.new_value);
      },
    },
    modelValue: {
      handler() {
        this.new_value = this.modelValue;
      },
    },
  },
};
</script>
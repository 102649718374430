<template>
  <v-row class="page-breadcrumb">
    <v-col cols="12" sm="12" class="pa-0">
      <v-card class="px-2 custom-shadow">
        <v-toolbar flat color="white" dense class="pa-0" height="40px">
          <v-icon class="pt-3 mr-3" v-if="$icons[module_name]" :icon="$icons[module_name]"/>
          <v-toolbar-title class="pt-3"><span> {{ $store.getters.translate(title) }}</span></v-toolbar-title>
          <template #extension>
            <v-toolbar-title class="toobar-extension bg-transparent pb-3">
              <v-breadcrumbs v-model:items="breadcrumbs" active-class="text-blue-darken-1" class="text-md-body-1 pa-0">
                <template v-slot:divider v-if="current">
                  <v-icon icon="mdi-chevron-right"/>
                </template>
              </v-breadcrumbs>
            </v-toolbar-title>
          </template>
          <v-spacer/>
        </v-toolbar>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import helpFunctions from "../../plugins/helpFunctions";

export default {
  name: "BaseBreadcrumb",
  props: [
    "module_name",
    "title",
    "current",
  ],
  data() {
    return {
      breadcrumbs: [],
    };
  },
  created() {
    this.breadcrumbs = helpFunctions.getBreadcrumbs(this.module_name, this.current);
  },
  watch: {
    current: {
      handler() {
        this.breadcrumbs = helpFunctions.getBreadcrumbs(this.module_name, this.current);
      },
    },
  },
};
</script>
<style lang="scss">
.page-breadcrumb {
  margin: -20px -25px 27px -25px;
}
</style>
<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn @click="closeModal(modal_name)">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title v-if="label">{{ $store.getters.translate(label) }}</v-toolbar-title>
            <v-toolbar-title v-else>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn v-if="canDelete(record.id || 0)" size="small" @click="archive()">
              <v-icon icon="mdi-trash-can" size="x-large"/>
            </v-btn>
            <v-btn v-if="canEdit(record.id || 0)" size="small" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
            <v-card-text class="ml-5 mr-5">
              <template v-for="field in usual_fields">
                <template v-if="field.name === 'description' || (page.model === 'distributiondevicefunction' && field.name === 'name')">
                  <v-text-field
                      color="primary"
                      variant="underlined"
                      :key="field.name"
                      :label="$store.getters.translate('en_name_desc')"
                      v-model="english_name"/>
                  <v-text-field
                      color="primary"
                      variant="underlined"
                      :key="field.name"
                      :label="$store.getters.translate('nl_name_desc')"
                      v-model="dutch_name"/>
                </template>
                <!--USUAL FIELDS-->
                <base-field
                    v-else
                    :isDisabled="!canEdit(record.id || 0)"
                    :key="field.name"
                    v-model="record[field.name]"
                    :field="field"
                    @change="usualFieldDataChanged"/>
              </template>
            </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import { useAbility } from "@casl/vue"
export default {
  props: ["modal_name", "page"],
  components: {
    BaseModal,
    BaseDraggableModal,
  },
  setup() {
    const { can } = useAbility()
    return {
      can
    }
  },
  data() {
    return {
      label: null,
      record: {},
      loading: false,
      usual_fields: [],
      english_name: null,
      dutch_name: null,
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    async load(id) {
      this.record = {};
      this.usual_fields = [];
      this.english_name = null;
      this.dutch_name = null;
      this.openModal(this.modal_name);
      this.loading = true;
      this.record = await helpFunctions.loadModel(this.$toast, this.page.name, id);
      if (this.page.model === 'distributiondevicefunction') {
        this.loadTranslation(this.record.name);
      }
      else {
        this.loadTranslation(this.record.description);
      }
      await this.getFields();
    },
    async save() {
      if(!this.loading) {
        //check if the key was created on model creation
        let key = '';
        if (this.page.model === 'distributiondevicefunction') {
          if(this.record.name) {
            key = this.record.name;
          }
        }
        else {
          if(this.record.description) {
            key = this.record.description;
          }
        }
        //create new key if it was not created on model creation
        let new_key = '';
        if(key !== '') {
          new_key = key;
        }
        else {
          if (this.english_name) {
            key = this.$lodash.lowerCase(this.english_name);
          }
          else if (this.dutch_name) {
            key = this.$lodash.lowerCase(this.dutch_name);
          }
          new_key = key.replaceAll('(', "_").replaceAll(')', "_").replaceAll('[', "_").replaceAll(']', "_")
              .replaceAll('-', "_").replaceAll('/', "_").replaceAll('\\', "_").replace(/ /g, "_");

          if (this.page.model === 'distributiondevicefunction') {
            this.record.name = new_key;
          }
          else {
            this.record.description = new_key;
          }
        }
        if (helpFunctions.modelFieldsFilled(this.$toast, this.record, this.usual_fields)) {
          this.loading = true;
          if(await helpFunctions.saveModel(this.$toast, this.page.name, this.record)) {
            if(this.english_name) {
              let english_translation = { key: new_key, language: 'en', value: this.english_name };
              await this.updateTranslation(english_translation);
            }
            if(this.dutch_name) {
              let dutch_translation = { key: new_key, language: 'nl', value: this.dutch_name };
              await this.updateTranslation(dutch_translation);
            }
            await this.$store.dispatch("refreshTranslations");
            this.$emit("refresh");
            this.closeModal(this.modal_name);
          }
          this.loading = false;
        }
      }
    },
    async archive() {
      this.$confirm(this.$store.getters.translate("delete_confirmation")).then(async (res) => {
        if (res) {
          this.loading = true;
          if (await helpFunctions.deleteModel(this.$toast, this.page.name, this.record.id)) {
            this.$emit("refresh");
            this.closeModal(this.modal_name);
          }
          this.loading = false;
        }
      });
    },
    loadTranslation(key) {
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/translations/get-by-key", { key: key })
          .then((response) => {
            response.data.forEach((translation) => {
              if (translation.language === 'en') {
                this.english_name = translation.value;
              }
              else if (translation.language === 'nl') {
                this.dutch_name = translation.value;
              }
            });
            this.loading = false;
          })
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    async updateTranslation(translation) {
      await this.$http
          .post(this.$store.getters.appUrl + "v2/translations/update-by-key", translation)
          .then(() => {})
          .catch((error) => {
            this.$toast.error(error.message);
            this.loading = false;
          });
    },
    usualFieldDataChanged(data) {
      this.record[data[0]] = data[1];
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_update");
      this.usual_fields = response.usual_fields;
      this.loading = false;
    },
    canEdit(id = 0) {
      return this.can('edit', this.$createEntity(this.page.model, {id}));
    },
    canDelete(id = 0) {
      return this.can("delete", this.$createEntity(this.page.model, {id}));
    },
  },
};
</script>
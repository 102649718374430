import { createVuetify, ThemeDefinition } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '../scss/vuetify/overrides.scss';
import 'vuetify/styles';
import '@mdi/font/css/materialdesignicons.css';

import {nl, en} from 'vuetify/locale'

export default createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
    },
    theme: {
        defaultTheme: 'light',
        themes: {
            dark: {
                dark: true,
                primary: '#497485',
                info: '#5E5E5E',
                success: '#02b325',
                accent: '#f0b216',
                default: '#636363',
                secondary: '#828282'
            },
            light: {
                dark: false,
                primary: '#497485',
                info: '#5E5E5E',
                success: '#02b325',
                accent: '#f0b216',
                default: '#636363',
                secondary: '#828282'
            }
        }
    },
    locale: {
        locale: 'nl',
        fallbackLocale: 'en',
        messages: {
            nl,
            en,
        },
    },
});

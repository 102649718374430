<template>
      <v-text-field
          variant="underlined"
          bg-color="white"
          color="primary"
          v-if="field.field_type === 'read_only'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value"
          disabled/>
      <v-text-field
          variant="underlined"
          bg-color="white"
          color="primary"
          :disabled="isDisabled"
          v-if="field.field_type === 'link'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value">
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="text-red"><strong>*</strong></span></template>
      </v-text-field>
      <v-text-field
          variant="underlined"
          bg-color="white"
          color="primary"
          :disabled="isDisabled"
          v-if="field.field_type === 'text_field' && (field.name === 'phonenumber' || field.name === 'mobilenumber')"
          :label="$store.getters.translate(field.name)"
          placeholder="+31612345678"
          v-model="new_value">
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="text-red"><strong>*</strong></span></template>
      </v-text-field>
      <v-text-field
          variant="underlined"
          bg-color="white"
          color="primary"
          :disabled="isDisabled"
          v-else-if="field.field_type === 'text_field'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value">
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="text-red"><strong>*</strong></span></template>
      </v-text-field>
      <v-textarea
          variant="outlined"
          bg-color="white"
          color="primary"
          :disabled="isDisabled"
          v-if="field.field_type === 'text_area'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value">
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="text-red"><strong>*</strong></span></template>
      </v-textarea>
      <v-text-field
          variant="underlined"
          bg-color="white"
          color="primary"
          :disabled="isDisabled"
          v-if="field.field_type === 'date'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value"
          v-mask="'##-##-####'"
          placeholder="dd-mm-yyyy">
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="text-red"><strong>*</strong></span></template>
      </v-text-field>
      <v-autocomplete
          variant="underlined"
          bg-color="white"
          color="primary"
          :disabled="isDisabled"
          v-if="field.field_type === 'select_single'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value"
          :return-object="true"
          item-value="value"
          item-title="text"
          clearable
          :items="$lodash.sortBy(field.available_options)">
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="text-red"><strong>*</strong></span></template>
        <template v-if="create" #append>
          <v-btn size="small" variant="flat" @click="createNewModel" :disabled="isDisabled">
            <v-icon color="primary" size="large" icon="mdi-plus"/>
          </v-btn>
        </template>
      </v-autocomplete>
      <v-autocomplete
          variant="underlined"
          bg-color="white"
          color="primary"
          :disabled="isDisabled"
          v-if="field.field_type === 'select_multiple'"
          :label="$store.getters.translate(field.name)"
          v-model="new_value"
          :return-object="false"
          item-value="value"
          item-title="text"
          clearable
          :items="$lodash.sortBy(field.available_options)"
          multiple>
        <template v-if="field.mandatory" #label>{{ $store.getters.translate(field.name) }}<span class="text-red"><strong>*</strong></span></template>
        <template v-if="create" #append>
          <v-btn size="small" variant="flat" @click="createNewModel" :disabled="isDisabled">
            <v-icon color="primary" size="large" icon="mdi-plus"/>
          </v-btn>
        </template>
      </v-autocomplete>
      <v-switch
          color="primary"
          density="compact"
          :disabled="isDisabled"
          v-if="field.field_type === 'boolean'"
          v-model="new_value"
          :label="$store.getters.translate(field.name)"/>
</template>

<script>
export default {
  props: ["modelValue", "field", "action", "isDisabled", "create"],
  data() {
    return {
      new_value: null,
    };
  },
  mounted() {
    if(this.field.field_type === 'select_single') {
      this.new_value = this.field.available_options.find(option => option.value === this.modelValue);
    }
    else {
      this.new_value = this.modelValue;
    }
  },
  methods: {
    createNewModel() {
      this.$emit("create", this.field.name);
    },
  },
  watch: {
    new_value: {
      handler() {
        let data = [];
        if(this.field.field_type === 'select_single') {
          if(this.new_value) {
            data = [this.field.name, this.new_value.value, this.new_value.text];
          }
          else {
            data = [this.field.name, null, ''];
          }
        }
        else {
          data = [this.field.name, this.new_value];
        }
        this.$emit("change", data);
      },
      deep: true
    },
    modelValue: {
      handler() {
        if(this.field.field_type === 'select_single') {
          this.new_value = this.field.available_options.find(option => option.value === this.modelValue);
        }
        else {
          this.new_value = this.modelValue;
        }
      },
      deep: true
    },
  },
};
</script>
<template>
  <BaseDraggableModal
      :name="modal_name"
      max-height="80vh"
      width="70vw"
      :fullscreen="$vuetify.display.xsOnly"
      :ref="modal_name">
    <v-card :loading="loading" :disabled="loading">
      <BaseModal>
        <template #header>
          <v-toolbar class="draggable_selector" color="primary" dense flat>
            <v-btn size="small" @click="reset()">
              <v-icon icon="mdi-close" size="x-large"/>
            </v-btn>
            <v-toolbar-title v-if="label">{{ $store.getters.translate(label) }}</v-toolbar-title>
            <v-toolbar-title v-else>{{ page.title }}</v-toolbar-title>
            <v-spacer/>
            <v-btn :disabled="loading" size="small" @click="save()">
              <v-icon icon="mdi-content-save" size="x-large"/>
            </v-btn>
          </v-toolbar>
        </template>
        <template #content>
            <v-card-text class="ml-5 mr-5">
              <!--USUAL FIELDS-->
              <base-field
                  v-for="field in usual_fields"
                  :key="field.name"
                  :isDisabled="field.name === 'date'"
                  v-model="record[field.name]"
                  :field="field"
                  @change="usualFieldDataChanged"/>
              <!--CUSTOM FIELDS-->
              <base-field
                  v-if="record.custom_fields"
                  v-for="field in custom_fields"
                  :key="field.name"
                  v-model="record.custom_fields[field.name]"
                  :field="field"
                  @change="customFieldDataChanged"/>
              <!--UPLOAD FILES-->
              <v-row v-if="files_required">
                <v-col v-cloak @drop.prevent="addDropFile" @dragover.prevent>
                  <v-file-input
                      class="mt-3"
                      :label="$store.getters.translate('files')"
                      placeholder="Drop file to upload"
                      show-size
                      v-model="new_files"
                      :multiple="multiple_files"/>
                </v-col>
              </v-row>
              <base-addresses v-if="addresses_required" v-model="record.addresses"/>
              <base-tags v-if="tags_required" :modelValue="record.tags" :modal_name="page.name" @change="tagsChanged"/>
            </v-card-text>
        </template>
      </BaseModal>
    </v-card>
  </BaseDraggableModal>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions";
import BaseModal from "../../components/commonComponents/BaseModal";
import BaseDraggableModal from "../../components/commonComponents/BaseDraggableModal";
import moment from "moment";
import BaseAddresses from "./BaseAddresses";

export default {
  props: ["modal_name", "page", "addresses_required", "files_required", "multiple_files"],
  components: {
    BaseAddresses,
    BaseModal,
    BaseDraggableModal,
  },
  data() {
    return {
      label: null,
      loading: false,
      record: {},
      usual_fields: [],
      custom_fields: [],
      tags_required: false,
      //MARKERING VARIABLES. NOT USED FOR OTHER MODELS
      regulation: null,
      gas_group: null,
      simple_protection_method: null,
      temperature_class: null,
      equipment_protection_level: null,
      //FILES VARIABLE
      new_files: []
    };
  },
  methods: {
    ...helpFunctions.modal_functions,
    load() {
      if(this.page.model === 'company') {
        this.record = {
          custom_fields: {},
        };
      }
      else {
        this.record = {};
      }
      this.openModal(this.modal_name);
      this.getFields();
    },
    async save() {
      if(!this.loading) {
        if (helpFunctions.modelFieldsFilled(this.$toast, this.record, this.usual_fields)) {
          this.loading = true;
          if(this.page.model === 'translation') {
            let new_key = this.$lodash.lowerCase(this.record.key);

            this.record.key = new_key.replaceAll('(', "_").replaceAll(')', "_").replaceAll('[', "_").replaceAll(']', "_")
                .replaceAll('-', "_").replaceAll('/', "_").replaceAll('\\', "_").replace(/ /g, "_");
          }
          let record_id = await helpFunctions.createModel(this.$toast, this.page.name, this.record);
          if(record_id) {
            if(this.new_files.length > 0) {
              await this.uploadFiles(this.new_files, record_id);
            }
            if(this.page.model === 'translation') {
              await this.$store.dispatch("refreshTranslations");
            }
            this.$emit("refresh", record_id);
            this.reset();
          }
          this.loading = false;
        }
      }
    },
    reset() {
      if(this.page.model === 'company') {
        this.record = {
          custom_fields: {},
        };
      }
      else {
        this.record = {};
      }
      this.new_files = [];
      this.closeModal(this.modal_name);
    },
    usualFieldDataChanged(data) {
      if(this.record[data[0]] !== data[1]) {
        this.record[data[0]] = data[1];
        if (this.page.model === 'markering' && (data[0] === 'regulation_id' || data[0] === 'simple_protection_method_id' || data[0] === 'gas_group_id' || data[0] === 'temperature_class_id' || data[0] === 'equipment_protection_level_id')) {
          if (data[0] === 'regulation_id') {
            if (data[2]) {
              this.regulation = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.regulation = null;
            }
          } else if (data[0] === 'simple_protection_method_id') {
            if (data[2]) {
              this.simple_protection_method = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.simple_protection_method = null;
            }
          } else if (data[0] === 'gas_group_id') {
            if (data[2]) {
              this.gas_group = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.gas_group = null;
            }
          } else if (data[0] === 'temperature_class_id') {
            if (data[2]) {
              this.temperature_class = data[2].split(" | ")[0]; //data[2] stores selected text value
              this.record.maximum_surface_temperature = data[2];
            } else {
              this.temperature_class = null;
              this.record.maximum_surface_temperature = '';
            }
          } else if (data[0] === 'equipment_protection_level_id') {
            if (data[2]) {
              this.equipment_protection_level = data[2].split(" | ")[0]; //data[2] stores selected text value
            } else {
              this.equipment_protection_level = null;
            }
          }
          this.record.name = "";
          if (this.regulation) {
            this.record.name = this.regulation + " ";
          }
          if (this.simple_protection_method) {
            this.record.name += this.simple_protection_method + " ";
          }
          if (this.gas_group) {
            this.record.name += this.gas_group + " ";
          }
          if (this.temperature_class) {
            this.record.name += this.temperature_class + " ";
          }
          if (this.equipment_protection_level) {
            this.record.name += this.equipment_protection_level;
          }
        }
      }
    },
    customFieldDataChanged(data) {
      this.record.custom_fields[data[0]] = data[1];
    },
    tagsChanged(data) {
      this.record.tags = data;
    },
    async getFields() {
      this.loading = true;
      const response = await helpFunctions.getFields(this.page.model, "show_on_creation");
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.loading = false;
    },
    addDropFile(e) {
      for (let i = 0; i < e.dataTransfer.files.length; i++) {
        this.new_files.push(e.dataTransfer.files[i]);
      }
    },
    async uploadFiles(files, record_id) {
      this.loading = true;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append("attachment[]", files[i]);
      }
      formData.append("id", record_id);
      await this.$http.post(this.$store.getters.appUrl + "v2/" + this.page.name + "/uploadfiles", formData, {headers: {"Content-Type": "multipart/form-data"}});
    },
  },
};
</script>